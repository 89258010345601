import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"

export const FaqSection = () => {
  const intl = useIntl()
  const { faqImage } = useStaticQuery(graphql`
    query {
      faqImage: file(
        relativeDirectory: { eq: "home" }
        name: { regex: "/home-faq/" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            height: 320
            placeholder: BLURRED
            quality: 90
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `)

  return (
    <div id="faq-section" className="w-10/12 px-2 py-10 mx-auto bg-white">
      <h2 className="text-5xl font-bold tracking-wide text-center uppercase font-PTSerif text-themePrimary">
        {intl.formatMessage({ id: "FAQ" })}
      </h2>

      <div
        className="flex flex-col items-center justify-center min-w-full py-10 mx-auto text-themePrimary lg:w-10/12 lg:flex-row lg:gap-x-16"
        id="faq-questions"
      >
        <GatsbyImage
          image={faqImage?.childImageSharp?.gatsbyImageData}
          alt="Home FAQ Section background"
        />
        <div className="flex-1">
          <div className="pb-5">
            <h3 className="pb-3 text-xl font-bold font-PTSerif">
              {intl.formatMessage({ id: "FAQTitle1" })}
            </h3>
            <p className="pb-5">{intl.formatMessage({ id: "FAQAnswer1" })}</p>
          </div>

          <div className="pb-5">
            <h3 className="pb-3 text-xl font-bold font-PTSerif">
              {intl.formatMessage({ id: "FAQTitle2" })}
            </h3>
            <p className="pb-5">{intl.formatMessage({ id: "FAQAnswer2" })}</p>
          </div>

          <div className="pb-5">
            <h3 className="pb-3 text-xl font-bold font-PTSerif">
              {intl.formatMessage({ id: "FAQTitle3" })}
            </h3>
            <p className="pb-5">{intl.formatMessage({ id: "FAQAnswer3" })}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqSection
