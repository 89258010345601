import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Autoplay, Navigation, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useIntl } from "gatsby-plugin-react-intl"
import RequestButton from "./RequestButton"

const HeroBanner = () => {
  const intl = useIntl()
  const { bannerSlides } = useStaticQuery(graphql`
    query {
      bannerSlides: allFile(
        filter: {
          relativeDirectory: { eq: "home" }
          name: { regex: "/home-slide/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(
              height: 510
              placeholder: BLURRED
              quality: 90
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  return (
    <div id="hero-banner" className="bg-white">
      <Swiper
        modules={[Navigation, Autoplay, EffectFade]}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 9000,
        }}
        navigation
        effect="fade"
      >
        {bannerSlides?.nodes.map((bannerSlide, index) => {
          return (
            <SwiperSlide
              className="relative flex justify-center items-center"
              key={bannerSlide?.id}
            >
              <GatsbyImage
                image={bannerSlide?.childImageSharp?.gatsbyImageData}
                alt="hero banner images"
                className="min-w-full min-h-[20rem] bg-image-darken"
              />
              {(function () {
                switch (index) {
                  case 0:
                    return (
                      <div className="w-10/12 mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-3 lg:gap-y-8 font-PTSerif justify-center items-center pb-5">
                        <div className="font-bold text-lg lg:text-4xl flex items-center gap-x-5 text-center">
                          <span className="text-white text-center">
                            {intl.formatMessage({ id: "homeBanner1Line1" })}
                          </span>
                        </div>
                        <div className="font-bold text-xl lg:text-4xl text-white capitalize text-center">
                          {intl.formatMessage({ id: "homeBanner1Line2" })}
                        </div>
                        <div className="text-base lg:text-xl text-white text-center mb-3">
                          {intl.formatMessage({ id: "homeBanner1Line3" })}
                        </div>
                        <RequestButton />
                      </div>
                    )
                  case 1:
                    return (
                      <div className="w-10/12 mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-2 lg:gap-y-8 font-PTSerif justify-center items-center">
                        <div className="font-bold text-xl lg:text-4xl flex items-center gap-x-5">
                          <span className="text-white text-center">
                            {intl.formatMessage({ id: "homeBanner2Line1" })}
                          </span>
                        </div>
                        <div className="font-bold text-lg lg:text-4xl text-white capitalize text-center">
                          {intl.formatMessage({ id: "homeBanner2Line2" })}
                        </div>
                        <div className="text-base lg:text-xl text-white text-center mb-3">
                          {intl.formatMessage({ id: "homeBanner2Line3" })}
                        </div>
                        <RequestButton />
                      </div>
                    )
                  default:
                    return null
                }
              })()}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default HeroBanner
