import React from "react"
import { graphql } from "gatsby"
import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import ExpertsSection from "../../components/ExpertsSection"
import FaqSection from "../../components/FaqSection"
import WhySection from "../../components/WhySection"
import HomeServices from "../../components/HomeServices"

const HomePage = ({ data: { page } }) => (
  <Layout>
    <h1 className="hidden">{page?.title}</h1>
    <Seo seoData={page?.seo} />
    <HeroBanner />
    <ExpertsSection />
    <FaqSection />
    <WhySection />
    <HomeServices />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  query HomePageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
  }
`
