import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"
import RequestButton from "./RequestButton"

export const HomeServices = () => {
  const intl = useIntl()
  const { expertsImages } = useStaticQuery(graphql`
    query {
      expertsImages: allFile(
        filter: {
          relativeDirectory: { eq: "home" }
          name: { regex: "/-services-/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(
              height: 320
              placeholder: BLURRED
              quality: 90
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  return (
    <div
      id="home-services-section"
      className="w-full mx-auto py-10 text-themePrimary relative flex justify-start items-center"
    >
      <div className="w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-5 items-center">
        <GatsbyImage
          image={expertsImages?.nodes[0].childImageSharp?.gatsbyImageData}
          alt="Personal Injury Attorney Long Island"
          className="min-w-full min-h-[25rem]"
        />
        <div>
          <p className="pb-3">
            {intl.formatMessage({ id: "HomeServicesPara1" })}
          </p>
          <p className="pb-3">
            {intl.formatMessage({ id: "HomeServicesPara2" })}
          </p>
          <p className="pb-3">
            {intl.formatMessage({ id: "HomeServicesPara3" })}
          </p>
          <p className="pb-3">
            {intl.formatMessage({ id: "HomeServicesPara4" })}
          </p>
        </div>
        <div>
          <p className="pb-3">
            <span className="font-PTSerif font-semibold">
              {intl.formatMessage({ id: "HomeServicesPara7" })}
            </span>
            <br />
            <br />
            {intl.formatMessage({ id: "HomeServicesPara5" })}
          </p>
          <p className="pb-3 mb-8">
            {intl.formatMessage({ id: "HomeServicesPara6" })}
          </p>
          <RequestButton />
        </div>
        <GatsbyImage
          image={expertsImages?.nodes[1].childImageSharp?.gatsbyImageData}
          alt="No-Fault Collections Attorney Long Island"
          className="min-w-full min-h-[25rem]"
        />
      </div>
    </div>
  )
}

export default HomeServices
